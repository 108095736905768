.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

.BottomNav{
  background-color: green;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.float{
	position:fixed;
	width:60px;
	height:60px;
	bottom:96px;
	right:10%;
	background-color:#28a745;
	color:#FFF;
	border-radius:50px;
	text-align:center;
	box-shadow: 2px 2px 3px #999;
  z-index: 99;
}

.my-float{
	margin-top:22px;
}
